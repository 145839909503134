.footercontain{
    position: relative;
   
}
.footercontain>hr{
    border: 1px silid lightgray;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    height: 20rem;
}
.social-l{
    display: flex;
    justify-content: center;
    gap: 4rem;
}
.social-l>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f{
    display: flex;
    justify-content: center;
}
.logo-f>img{
    width: 10rem;
    
}
.footer-blur1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: 200px;
    background: red;
}
.footer-blur2{
    bottom: 0;
   left: 15%;
    width: 26rem;
    height: 12rem;
    filter: 200px;
    background: rgb(207, 118, 49);
}